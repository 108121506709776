import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from '../util/data';

// Return captilized string
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string') {
    return '';
  }
  const string = str && str.toString();
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
};

export const isArrayLength = array => {
  return Array.isArray(array) && array.length;
};

export const getSubscriptionPlanName = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.planName;
};

export const getSubscription = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.subscriptionPlan;
};

export const getSubscriptionData = user => {
  return user && user.id && user?.attributes?.profile?.privateData?.subscriptionData;
};

export const getUserType = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.type;
};

export const isUserApproved = user => {
  return user && user.id && user?.attributes?.profile?.publicData?.isApproved;
};

/**
 * Returns an array of wishlist items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of wishlist items, or an empty array if input is invalid or wishlist is missing.
 */
export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.wishlist) || []
  );
};

export const getUserFullname = user => {
  // Combine first and last names using optional chaining and nullish coalescing
  return user?.attributes?.profile?.firstName
    ? `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`
    : user?.attributes?.profile?.publicData?.fullName;
};

export function getClientSecret(setupIntent) {
  return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
}

export function getPaymentParams(currentUser, formValues) {
  const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
  const addressMaybe =
    addressLine1 && postal
      ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
      : {};
  const billingDetails = {
    name,
    email: ensureCurrentUser(currentUser).attributes.email,
    ...addressMaybe,
  };

  const paymentParams = {
    payment_method_data: {
      billing_details: billingDetails,
    },
  };

  return paymentParams;
}

export const getDefaultPaymentMethod = user => {
  const hasDefaultPaymentMethod =
    user?.id &&
    ensureStripeCustomer(user.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(user.stripeCustomer.defaultPaymentMethod).id;
  return hasDefaultPaymentMethod ?? null;
};

export const getProductPriceDetails = product => {
  const pricingPlanNames = {
    vivian_lab_3_months: 'VivianLab 3 Months Subscription',
    vivian_lab_12_months: 'Vivianlab Subscription',
  };

  const priceAmount = (product?.unit_amount || product?.amount) ?? 0;
  const priceCurrency = product?.currency ?? 'USD';

  // Map price nickname to human-readable name using a lookup table
  const priceName = pricingPlanNames[product?.lookupKey] ?? '';
  const priceId = product?.id ?? 'unknown';
  const status = product?.status ?? 'unknown';
  const heading = product?.heading ?? 'unknown';
  const productBreakdown
  = product?.productBreakdown
  ?? 'unknown';
  const perYear = product?.perYear ?? 'unknown';
  // Calculate formatted price with currency handling
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: priceCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(priceAmount / 100);

  return {
    price: formattedPrice,
    currency: priceCurrency,
    priceName,
    priceId,
    status,
    heading,
    productBreakdown,
    perYear
  };
};
