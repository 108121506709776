/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES, draftId, draftSlug } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  ExternalLink,
  IconSearch,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';
import Icons from '../../Icons/Icons';
import { useConfiguration } from '../../../context/configurationContext';
import { USER_TYPE } from '../../../helpers/enums';
import { createSlug } from '../../../util/urlHelpers';
import { selectIsPayoutEnabled } from '../../../ducks/user.duck';
import { useSelector } from 'react-redux';
import { isIndividualUser } from '../../../util/dataExtractors';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    ownListing,
  } = props;

  const isPayoutEnabled = useSelector(selectIsPayoutEnabled);
  const showPayoutLinkButton = !isPayoutEnabled && ownListing?.attributes?.state === 'published';

  const user = ensureCurrentUser(currentUser);
  const config = useConfiguration();
  const { siteFacebookPage, siteInstagramPage, siteLinkedInPage } = config;
  if (!isAuthenticated) {
    const signup = (
      <NamedLink
        name="AuthenticationPageSelector"
        params={{ type: USER_TYPE.DOCTOR }}
        className={css.signupLink}
      >
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <div className={css.authBtns}>
              {signup} {login}
            </div>
          </div>
          <div className={css.browseSec}>
            <ul className={css.list}>
              {/* <li className={css.listItem}>
                <NamedLink name="LandingPage" className={css.link}>
                  <Icons name="individual" />
                  <FormattedMessage id="Footer.individual" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="DoctorsPage" className={css.link}>
                  <Icons name="doctors" />
                  <FormattedMessage id="Footer.doctorsAndProviders" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="CompaniesPage" className={css.link}>
                  <Icons name="company" />
                  <FormattedMessage id="Footer.companies" />
                </NamedLink>
              </li> */}
              <li className={css.listItem}>
                <NamedLink name="MenopausePage" className={css.link}>
                  <Icons name="menopause" />
                  <FormattedMessage id="TopbarDesktop.menopauseLink" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="DoctorsPage" className={css.link}>
                  <Icons name="doctors" />
                  <FormattedMessage id="TopbarDesktop.doctorsLink" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="CompaniesPage" className={css.link}>
                  <Icons name="company" />
                  <FormattedMessage id="TopbarDesktop.companiesLink" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="SexLandingPage" className={css.link}>
                  <Icons name="sexualWellness" />
                  <FormattedMessage id="TopbarDesktop.sexualWellnessLink" />
                </NamedLink>
              </li>
              {/* <li className={css.listItem}>
                <NamedLink name="BlogPage" className={css.link}>
                  <Icons name="blog" />
                  <FormattedMessage id="Footer.blog" />
                </NamedLink>
              </li> */}
              <li className={css.listItem}>
                <NamedLink name="AboutPage" className={css.link}>
                  <Icons name="about" />
                  <FormattedMessage id="Footer.toAboutPage" />
                </NamedLink>
              </li>
              {/* <li className={css.listItem}>
                <NamedLink name="ContactPage" className={css.link}>
                  <Icons name="contact" />
                  <FormattedMessage id="Footer.contactUs" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="FAQPage" className={css.link}>
                  <Icons name="blog" />
                  <FormattedMessage id="Footer.faq" />
                </NamedLink>
              </li> */}
              {/* <li className={css.listItem}>
                <NamedLink name="TermsOfServicePage" className={css.link}>
                  <Icons name="profile" />
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  <Icons name="profile" />
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  <Icons name="profile" />
                  <FormattedMessage id="Footer.cookiePolicy" />
                </NamedLink>
              </li> */}
            </ul>
          </div>
        </div>
        <div className={css.footerSocialIcon}>
          <ul>
            <li>
              <ExternalLink
                href={siteFacebookPage}
                name="PrivacyPolicyPage"
                className={css.facebook}
              >
                <Icons name="facebook" />
              </ExternalLink>
            </li>
            {/* <li>
              <ExternalLink href="" name="PrivacyPolicyPage" className={css.twitter}>
                <Icons name="twitter" />
              </ExternalLink>
            </li> */}
            <li>
              <ExternalLink
                href={siteInstagramPage}
                name="PrivacyPolicyPage"
                className={css.instagram}
              >
                <Icons name="instagram" />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                href={siteLinkedInPage}
                name="PrivacyPolicyPage"
                className={css.linkedin}
              >
                <Icons name="linkedin" />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                href="mailto:info@vivianlab.com"
                name="PrivacyPolicyPage"
                className={css.email}
              >
                <Icons name="email" />
              </ExternalLink>
            </li>
          </ul>
          <p>© 2023 VivianLab</p>
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const showEditApplication = ownListing?.attributes?.state === 'pendingApproval';

  return (
    <div className={css.root}>
      <div className={css.content}>
        <AvatarLarge className={css.avatar} user={currentUser} />
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarDesktop.logout" />
        </InlineTextButton>
        {(isAuthenticated && !isIndividualUser(currentUser) && !ownListing) ||
        showEditApplication ? (
          <NamedLink
            className={classNames(css.inbox, currentPageClass('DoctorOnboardingPage'))}
            name="DoctorOnboardingPage"
          >
            <FormattedMessage
              id={
                ownListing
                  ? 'TopbarDesktop.editOnboardingApplication'
                  : 'TopbarDesktop.startOnboardingApplication'
              }
            />
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarDesktop.inbox" />
          {notificationCountBadge}
        </NamedLink>
        {isAuthenticated && isIndividualUser(currentUser) ? (
          <NamedLink
            className={classNames(css.inbox, currentPageClass('MyInvoicesPage'))}
            name="MyInvoicesPage"
          >
            <FormattedMessage id="TopbarDesktop.myInvoicesPage" />
          </NamedLink>
        ) : null}
        {isAuthenticated && currentUserHasListings ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('EditListingPage'))}
            name="EditListingPage"
            params={{
              id: ownListing?.id?.uuid || draftId,
              slug: createSlug(ownListing?.attributes?.title || draftSlug),
              type: 'edit',
              tab: 'details',
            }}
          >
            <FormattedMessage id="TopbarDesktop.editProfile" />
          </NamedLink>
        ) : null}
        {/* <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink> */}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FAQPage'))}
          name="FAQPage"
        >
          <FormattedMessage id="Footer.faq" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      {/* <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
