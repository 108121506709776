import React from 'react';
import { Menu, MenuLabel, MenuContent, MenuItem, InlineTextButton } from '..';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import englishImage from './flags/english.png';
import greekImage from './flags/greek.png';
import germanImage from './flags/german.png';
import css from './LocaleMenu.module.css';
import { getDefaultLocale } from '../../util/genericHelpers';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { NON_TRANSLATED_PATHS } from '../../helpers/enums';

const localeOptions = [
  { key: 'el', label: 'Greek', flag: greekImage },
  { key: 'en', label: 'English', flag: englishImage },
  { key: 'de', label: 'German', flag: germanImage },
];

const LocaleMenu = props => {
  const { className, rootClassName, isfooterLanguage } = props;
  const classes = classNames(rootClassName || css.root, className);
  const config = useConfiguration();
  const location = useLocation();

  const onChangeLocale = (e, locale) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof window !== 'undefined') {
      const currentLocaleFromStorage = getDefaultLocale();
      if (currentLocaleFromStorage !== locale) {
        window.localStorage.setItem('locale', locale);
        window.location.reload();
      }
      const pathname = window.location.pathname;
      if (pathname.includes('blog')) {
        const redirectTo = '/blogs';
        window.location.href = redirectTo;
      }
    }
  };

  const locale = localeOptions.find(l => l.key === config.localization.locale);


  return (
    <Menu className={classes}>
      <MenuLabel className={css.menuLabel}>
        {!isfooterLanguage ? (
          <span className={css.countryImg}>
            <img className={css.localeFlag} src={locale.flag} alt={locale?.label} />
          </span>
        ) : (
          <span className={css.countryName}>{locale.label}</span>
        )}
        {!isfooterLanguage ? (
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0486 2.53199C11.5942 1.91614 11.1514 0.952409 10.3229 0.952409L1.22293 0.95241C0.394492 0.95241 -0.0483079 1.91614 0.497246 2.53199L4.75692 7.34008C5.29484 7.94719 6.25102 7.94718 6.78894 7.34008L11.0486 2.53199ZM9.89789 2.09527L5.91808 6.58751C5.84124 6.67424 5.70462 6.67424 5.62779 6.58751L1.64799 2.09527L9.89789 2.09527Z"
              fill="#835358"
            />
          </svg>
        ) : (
          <svg
            width="17"
            height="10"
            viewBox="0 0 17 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 1L8.32927 8L15.5 1" stroke="white" strokeWidth="2" />
          </svg>
        )}
      </MenuLabel>
      <MenuContent className={css.menuContent} arrowPosition={30} style={{ right: '100px' }}>
        {localeOptions.map(l => {
          const menuItemClasses = classNames(css.menuItem, {
            [css.menuItemActive]: locale.key === l.key,
          });
          return (
            <MenuItem key={l.key} className={menuItemClasses}>
              <InlineTextButton
                className={css.menuItemButton}
                type="button"
                onClick={event => onChangeLocale(event, l.key)}
              >
                {!isfooterLanguage ? (
                  <img className={css.flag} src={l.flag} alt={l.countryName} />
                ) : (
                  ' '
                )}
                <span className={css.label}>{l.label}</span>
              </InlineTextButton>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

export default LocaleMenu;
