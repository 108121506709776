import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import routeConfiguration, {
  ACCOUNT_SETTINGS_PAGES,
  draftId,
  draftSlug,
} from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ReactSelect,
} from '../../../components';
import { useLocation } from 'react-router-dom';
import { PAGES, USER_TYPE } from '../../../helpers/enums';
import LocaleMenu from '../../LocaleMenu/LocaleMenu';
import IconUser from '../../IconUser/IconUser';
import IconArrowRight from '../../IconArrowRight/IconArrowRight';
import {
  canAccessDashboard,
  isIndividualUser,
  isUsersStripeConnected,
} from '../../../util/dataExtractors';
import { createSlug } from '../../../util/urlHelpers';
import { selectIsPayoutEnabled } from '../../../ducks/user.duck';
import { useSelector } from 'react-redux';

import css from './TopbarDesktop.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Icons from '../../Icons/Icons';

import Select from 'react-select';
import { createResourceLocatorString } from '../../../util/routes';
const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    intl,
    isAuthenticated,
    onLogout,
    isFullWidthHeader,
    isHeaderSticky,
    staticPageHeader,
    dashboardHeader,
    ownListing,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const isStripeConnected = isUsersStripeConnected(currentUser);
  const isPayoutEnabled = useSelector(selectIsPayoutEnabled);

  const isMenopausePage = location?.pathname === PAGES.MENOPAUSE_LANDING_PAGE;
  const isDoctorsPage = location?.pathname === PAGES.DOCTORS_PAGE;
  const isCompaniesPage = location?.pathname === PAGES.COMPANIES_PAGE;
  const isBlogPage = location?.pathname === PAGES.BLOG_PAGE;
  const isAboutPage = location?.pathname === PAGES.ABOUT_PAGE;
  const isGalleryPage = location?.pathname === PAGES.GALLERY_PAGE;
  const isContactPage = location?.pathname === PAGES.CONTACT_PAGE;
  const isSignupPage = location?.pathname === PAGES.AUTH_PAGE;
  const isDashboardPage = [PAGES.VOUCHERS_PAGE, PAGES.DASHBOARD_PAGE].includes(location?.pathname);

  const [mounted, setMounted] = useState(false);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className, {
    [css.stickNav]: isHeaderSticky,
    [css.staticPageHeader]: staticPageHeader,
  });

  // const search = (
  //   <TopbarSearchForm
  //     className={css.searchLink}
  //     desktopInputRoot={css.topbarSearchWithLeftPadding}
  //     onSubmit={onSearchSubmit}
  //     initialValues={initialSearchFormValues}
  //     appConfig={appConfig}
  //   />
  // );

  // const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const showPayoutLinkButton = !isPayoutEnabled && ownListing?.attributes?.state === 'published';
  const showEditApplication = ownListing?.attributes?.state === 'pendingApproval';

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <FormattedMessage id="TopbarDesktop.inbox" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SubscriptionPage">
          <NamedLink
            name="SubscriptionPage"
            className={classNames(css.yourListingsLink, currentPageClass('SubscriptionPage'))}
          >
            <FormattedMessage id="TopbarDesktop.mySubscriptionPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyInvoicesPage">
          <NamedLink
            name="MyInvoicesPage"
            className={classNames(css.yourListingsLink, currentPageClass('MyInvoicesPage'))}
          >
            <FormattedMessage id="TopbarDesktop.myInvoicesPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            <IconArrowRight />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <FormattedMessage id="TopbarDesktop.logout" />
            <IconArrowRight />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const postApprorvalProfileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <div className={css.profileMenuHead}></div>
        </MenuItem>
        <MenuItem key="EditListingPage">
          {ownListing ? (
            <NamedLink
              name="EditListingPage"
              params={{
                id: ownListing?.id?.uuid || draftId,
                slug: createSlug(ownListing?.attributes?.title || draftSlug),
                type: ownListing ? 'edit' : 'new',
                tab: 'details',
              }}
              className={classNames(css.yourListingsLink, currentPageClass('EditListingPage'))}
            >
              <FormattedMessage id="TopbarDesktop.editProfile" />
              <IconArrowRight />
            </NamedLink>
          ) : (
            <NamedLink
              name="DoctorOnboardingPage"
              className={classNames(css.yourListingsLink, currentPageClass('DoctorOnboardingPage'))}
            >
              <FormattedMessage id="TopbarDesktop.completeProfile" />
              <IconArrowRight />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <FormattedMessage id="TopbarDesktop.inbox" />
            <IconArrowRight />
            {/* {notificationDot} */}
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            <IconArrowRight />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <FormattedMessage id="TopbarDesktop.logout" />
            <IconArrowRight />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="AuthenticationPageSelector"
      params={{ type: USER_TYPE.DOCTOR }}
      className={classNames(css.navLink, {
        [css.active]: isSignupPage,
        [css.signup]: !staticPageHeader,
      })}
    >
      {staticPageHeader ? <FormattedMessage id="TopbarDesktop.signup" /> : <IconUser />}
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  const dashboardLink = isAuthenticatedOrJustHydrated ? (
    <NamedLink name="DashboardPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.dashboardLink" />
    </NamedLink>
  ) : null;

  const menopauseLink = (
    <NamedLink
      name="MenopausePage"
      className={classNames(css.navLink, {
        [css.active]: isMenopausePage,
      })}
    >
      <FormattedMessage id="TopbarDesktop.menopauseLink" />
    </NamedLink>
  );

  const doctorsLink = (
    <NamedLink
      name="DoctorsPage"
      className={classNames(css.navLink, {
        [css.active]: isDoctorsPage,
      })}
    >
      <FormattedMessage id="TopbarDesktop.doctorsLink" />
    </NamedLink>
  );

  const ownProfileLink =
    isAuthenticated && currentUserHasListings && showPayoutLinkButton ? (
      <NamedLink
        name="EditListingPage"
        params={{
          id: ownListing?.id?.uuid || draftId,
          slug: createSlug(ownListing?.attributes?.title || draftSlug),
          type: ownListing?.id?.uuid ? 'edit' : 'new',
          tab: 'details',
        }}
        className={css.navLink}
      >
        <FormattedMessage id="TopbarDesktop.completeProfile" />
      </NamedLink>
    ) : null;

  const startOnboardingLink =
    (isAuthenticated && !isIndividualUser(currentUser) && !ownListing) || showEditApplication ? (
      <NamedLink name="DoctorOnboardingPage" className={css.navLink}>
        <FormattedMessage
          id={
            ownListing
              ? 'TopbarDesktop.editOnboardingApplication'
              : 'TopbarDesktop.startOnboardingApplication'
          }
        />
      </NamedLink>
    ) : null;

  const companiesLink = (
    <NamedLink
      name="CompaniesPage"
      className={classNames(css.navLink, {
        [css.active]: isCompaniesPage,
      })}
    >
      <FormattedMessage id="TopbarDesktop.companiesLink" />
    </NamedLink>
  );

  const blogLink = (
    <NamedLink
      name="BlogPage"
      className={classNames(css.navLink, {
        [css.active]: isBlogPage,
      })}
    >
      <FormattedMessage id="TopbarDesktop.blogLink" />
    </NamedLink>
  );

  const galleryLink = (
    <NamedLink
      name="VideoGalleryPage"
      className={classNames(css.navLink, {
        [css.active]: isGalleryPage,
      })}
    >
      <FormattedMessage id="TopbarDesktop.galleryLink" />
    </NamedLink>
  );

  const aboutLink = (
    <NamedLink
      name="AboutPage"
      className={classNames(css.navLink, {
        [css.active]: isAboutPage,
      })}
    >
      <FormattedMessage id="TopbarDesktop.aboutLink" />
    </NamedLink>
  );

  const contactLink = (
    <NamedLink
      name="ContactPage"
      className={classNames(css.navLink, {
        [css.active]: isContactPage,
      })}
    >
      <FormattedMessage id="TopbarDesktop.contactLink" />
    </NamedLink>
  );

  const faqLink = (
    <NamedLink name="FAQPage" className={css.navLink}>
      <FormattedMessage id="Footer.faq" />
    </NamedLink>
  );

  const customStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '18px',
        fontWeight: '400',
        color: '#fff',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? '#eee'
          : isSelected
          ? '#ee8b51'
          : isFocused
          ? '#ee8b51'
          : null,
        color: isDisabled ? '#484848' : isSelected ? '#fff' : isFocused ? '#fff' : '#000',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '14px',
        fontWeight: '400',
      };
    },
    placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: !staticPageHeader
          ? '#835358'
          : isDisabled
          ? '#fff'
          : isSelected
          ? '#fff'
          : isFocused
          ? '#fff'
          : '#fff',
        fontSize: '18px',
        fontWeight: !staticPageHeader ? '700' : '400',
      };
    },
    valueContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        paddingRight: '0',
        paddingLeft: '0',
        paddingTop: '0',
        paddingBottom: '0',
        lineHeight: '100%',
      };
    },

    singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? '#fff' : isSelected ? '#fff' : isFocused ? '#fff' : '#fff',
        fontSize: '14px',
        fontWeight: '400',
        margin: '0',
      };
    },
    input: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? '#fff' : isSelected ? '#fff' : isFocused ? '#fff' : '#fff',
        fontSize: '14px',
        fontWeight: '400',
        margin: '0',
      };
    },
    dropdownIndicator: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: !staticPageHeader
          ? '#835358'
          : isDisabled
          ? '#fff'
          : isSelected
          ? '#fff'
          : isFocused
          ? '#fff'
          : '#fff',
        fontSize: '14px',
        fontWeight: '400',
        margin: '0',
        padding: '0',
        '&:hover': {
          color: 'white',
        },
      };
    },
    menu: base => ({
      ...base,
      width: 'max-content',
      minWidth: '150px',
      padding: '0',
    }),
  };

  const options = [
    {
      label: intl.formatMessage({ id: 'TopbarDesktop.sexualWellnessLink' }),
      value: 'SexLandingPage',
    },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const preLoginLinks = !isAuthenticated ? (
    <>
      {menopauseLink}
      {doctorsLink}
      {/* {companiesLink} */}
      {/* {blogLink} */}
      {companiesLink}
      {/* {galleryLink} */}
      <div
        className={classNames(css.programs, css.navLink, {
          [css.fixedHeader]: !staticPageHeader,
        })}
      >
        <Select
          defaultValue={selectedOption}
          onChange={o => {
            setSelectedOption(o);
            history.push(createResourceLocatorString(o.value, routeConfiguration(), {}, {}));
          }}
          options={options}
          styles={customStyles}
          placeholder={intl.formatMessage({ id: 'TopbarDesktop.carePrograms' })}
          components={{
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </div>
      {aboutLink}
      {/* {faqLink}
      {contactLink}
      {loginLink} */}
      {signupLink}
    </>
  ) : null;

  const renderLinkedLogo = (
    <LinkedLogo
      className={css.logoLink}
      format="desktop"
      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
    />
  );

  const renderExtraLinks = (
    <>
      {isStripeConnected ? postApprorvalProfileMenu : profileMenu}
      <LocaleMenu className={css.languageButton} />
    </>
  );

  return (
    <>
      {mounted ? (
        staticPageHeader ? (
          <div
            className={classNames(
              isFullWidthHeader ? css.fullWidthContainer : css.fixedWidthContainer,
              dashboardHeader ? css.dashboardHeader : null
            )}
          >
            <nav className={classes}>
              {renderLinkedLogo}
              <div className={css.middleLinks}>
                {preLoginLinks}
                {ownProfileLink}
                <div className={css.rightLinks}>{renderExtraLinks}</div>
              </div>
            </nav>
          </div>
        ) : (
          <div
            className={classNames(
              isFullWidthHeader ? css.fullWidthContainer : css.fixedWidthContainer,
              dashboardHeader ? css.dashboardHeader : null
            )}
          >
            <nav className={classes}>
              {renderLinkedLogo}
              <div className={css.rightLinks}>
                <div className={css.middleLinks}>
                  {preLoginLinks}
                  {ownProfileLink}
                  {startOnboardingLink}
                  {canAccessDashboard(currentUser) && dashboardLink}
                </div>
                {renderExtraLinks}
              </div>
            </nav>
          </div>
        )
      ) : null}
    </>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
